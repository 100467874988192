<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Starter component
 */
export default {
  page: {
    title: "Catalogue des services",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Catalogue des services",
      requests: [
        {
          title: "Gestion d'accès",
          route: "service_catalog.access",
          description: "Requêtes",
          icon: "access",
          guard: ["isp_acces_log", "isp_acces_log_approve_entry", "isp_acces_log_approve_exit"],
          disabled: false,
        },
        {
          title: "Requêtes",
          route: "requests",
          description: "Requêtes",
          icon: "request",
          guard: ["create_mr", "edit_mr", "delete_mr", "view_mr", "list_mr", 'create_vsr','edit_vsr','delete_vsr','view_vsr','list_vsr', 'create_tamr', 'edit_tamr', 'delete_tamr', 'view_tamr', 'list_tamr', 'create_dr', 'edit_dr', 'delete_dr', 'view_dr', 'list_dr'],
          disabled: false,
        },
        {
          title: "Restauration",
          route: "service_catalog.restauration",
          description: "",
          icon: "restauration",
          guard: ["create_rest_ods", "edit_rest_ods", "delete_rest_ods", "view_rest_ods", "list_rest_ods"],
          disabled: false,
        },

        {
          title: "Hébergement",
          route: "ops.hosting.index",
          description: "",
          icon: "hosting",
          guard: [ "create_host_ods", "edit_host_ods", "delete_host_ods", "view_host_ods", "list_host_ods", "create_hosting_booking", "update_hosting_booking", "read_hosting_booking", "delete_hosting_booking", "list_hosting_booking", "approve_booking", "reject_booking", "checkin_booking", "checkout_booking"],
          disabled: false,
        },

        {
          title: "Espaces Verts",
          route: "service_catalog.green_areas",
          description: "",
          icon: "gardening",
          guard: [ "create_gs_ods", "edit_gs_ods", "delete_gs_ods", "view_gs_ods", "list_gs_ods"],
          disabled: false,
        },

        {
          title: "Opérations 3D",
          route: "service_catalog.operations",
          description: "",
          icon: "extermination",
          guard: [
            "create_threedops_ods",
            "edit_threedops_ods",
            "delete_threedops_ods",
            "view_threedops_ods",
            "list_threedops_ods",
          ],
          disabled: false,
        },

        {
          title: "Maintenance",
          route: "service_catalog.maintenance.index",
          description: "",
          icon: "maintenance",
          guard: ["create_dr", "edit_dr", "delete_dr", "view_dr", "list_dr"],
        },
        
        {
          title: "Autres Services",
          route: "service_catalog.custom_services",
          description: "",
          icon: "custom",
          guard: [
            'create_custom_ods',
            'edit_custom_ods',
            'delete_custom_ods',
            'view_custom_ods',
            'list_custom_ods',
          ],
          disabled: false,

        },

        {
          title: "Stock",
          route: "service_catalog.stock",
          description: "",
          icon: "packages",
          guard: ['create_stock_entry', 'edit_stock_entry', 'delete_stock_entry', 'view_stock_entry', 'list_stock_entry', 'create_stock_exit', 'edit_stock_exit', 'delete_stock_exit','view_stock_exit', 'list_stock_exit'],
          disabled: false,

        },

        {
          title: "HSE",
          route: "service_catalog.hse",
          description: "",
          icon: "hse",
          guard: ["create_control", "edit_control", "delete_control", "view_control", "list_control"],
          disabled: false,

        },

        {
          title: "Transport du Personnel",
          route: "service_catalog.personels_transport",
          description: "",
          icon: "employee-transport",
          guard: ["personal_transp_log", "personal_transp_log_approve_entry", "personal_transp_log_approve_exit"],
          disabled: false,

        },

        {
          title: "Entretien & Nettoyage",
          route: "service_catalog.cleaning&Maintenance",
          description: "",
          icon: "cleaning",
          guard: [
            "create_tamr",
            "edit_tamr",
            "delete_tamr",
            "view_tamr",
            "list_tamr",
          ],
          disabled: true,

        },

        {
          title: "Blanchisserie",
          route: "requests.takeawaymeals.index",
          description: "",
          icon: "laundry",
          guard: [
            "create_tamr",
            "edit_tamr",
            "delete_tamr",
            "view_tamr",
            "list_tamr",
          ],
          disabled: true,

        },

        
        {
          title: "Transport de Marchandise",
          route: "requests.takeawaymeals.index",
          description:
            "",
          icon: "cargo",
          guard: ['create_tamr', 'edit_tamr', 'delete_tamr', 'view_tamr', 'list_tamr',],
          disabled: true,
        },
        {
          title: "Autres Prestations & Fournitures",
          route: "requests.takeawaymeals.index",
          description:
            "",
          icon: "miscellaneous",
          guard: ['create_tamr', 'edit_tamr', 'delete_tamr', 'view_tamr', 'list_tamr',],
          disabled: true,
        },
      ],
      items: [],
    };
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row settings">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xs-12 col-md-3 col-lg-3 setting-item"  v-for="request in requests" v-bind:key="request.title">
                <router-link :class="{ 'disabledItem': !canAny(request.guard) || request.disabled }" :to="(!request.disabled && canAny(request.guard)) ? { name: request.route } : ''">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">
                        <img
                          :src="require('@/assets/images/base/menu/' + request.icon +'.png')"
                          :alt="request.title"
                          :title="request.title"
                          class="rounded-0"
                        />
                        <h4 class="card-title" >{{request.title}}</h4>
                      </h4>
                      <p class="card-text">
                        <!-- With supporting text below as a natural lead-in to
                        additional content. -->
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-none">
      <div
        class="col-lg-3 azmt-service-card"
        v-for="request in requests"
        v-bind:key="request.title"
      >
        <router-link v-if="canAny(request.guard)" :to="!request.disabled ? { name: request.route } : '' " :class="request.disabled ? 'disabledItem' : ''">
          <b-card no-body>
            <b-row no-gutters class="align-items-center">
              <b-col md="8">
                <b-card-body :title="request.title"> </b-card-body>
              </b-col>
              <b-col md="4">
                <b-card-img
                  :src="
                    require('@/assets/images/base/menu/' +
                      request.icon +
                      '.png')
                  "
                  :alt="request.title"
                  :title="request.title"
                  class="rounded-0"
                ></b-card-img>
              </b-col>
            </b-row>
          </b-card>
        </router-link>
      </div>
    </div>
  </Layout>
</template>
